import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import axios from 'axios'
 
  //  axios.defaults.headers.common = {
  //    'X-Requested-With': 'XMLHttpRequest',
  //    'X-CSRF-TOKEN': this.$page.props.csrf_token,
  //  };

export const useMainStore = defineStore('main', () => {
  const userName = ref('John Doe')
  const userEmail = ref('doe.doe.doe@example.com')

  // const userAvatar = computed(
  //   () =>
  //     `https://api.dicebear.com/7.x/avataaars/svg?seed=${userEmail.value.replace(
  //       /[^a-z0-9]+/gi,
  //       '-'
  //     )}`
  // )
  const userAvatar = null;
  const isFieldFocusRegistered = ref(false)

  const clients = ref([])
  const history = ref([])
  const quizs = ref([])
  const companies = ref([])
  const projects_in_company = ref([])

  function setUser(payload) {
    if (payload.name) {
      userName.value = payload.name
    }
    if (payload.email) {
      userEmail.value = payload.email
    }
  }

  function setQuizs(data) {
    quizs.value = data
  }

  function set_projects_in_company(data) {
    projects_in_company.value = data
  }

  function setCompanies(data) {
    companies.value = data
  }

  function fetchQuizs() {

    // axios.get('/sanctum/csrf-cookie').then(response => {
    //   // Теперь можно безопасно отправлять POST запросы
    //   axios.get('/api/quizs').then(response => {
    //     quizs.value = result?.data
    //   }).catch(error => {
    //     console.error('Error accessing protected route:', error);
    //   });;
    // });

    axios
      .get(`/api/quizs`)
      .then((result) => {
        quizs.value = result?.data
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  const projectResults = ref({})

  function getResults(project) {
    console.log('project', project)
    axios
      .get(`/api/quizs/${project.id}/results`)
      .then((result) => {
        projectResults.value = result?.data
      })
      .catch((error) => {
        alert(error.message)
      })
  }



  function fetchSampleClients() {
    axios
      .get(`data-sources/clients.json?v=3`)
      .then((result) => {
        clients.value = result?.data?.data
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  function fetchSampleHistory() {
    axios
      .get(`data-sources/history.json`)
      .then((result) => {
        history.value = result?.data?.data
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  return {
    userName,
    userEmail,
    userAvatar,
    isFieldFocusRegistered,
    clients,
    quizs,
    history,
    companies,
    projects_in_company,
    projectResults,
    set_projects_in_company,
    setUser,
    fetchSampleClients,
    fetchSampleHistory,
    fetchQuizs,
    setQuizs,
    setCompanies,
    getResults
  }
})
